import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#8fbcbb',
        secondary: '#88c0d0', 
        accent: '#81a1c1',
        error: '#5e81ac',
      },
    },
  },
})

export default vuetify 
