export default [
  {
    path: '/siembras', 
    name: 'siembras', 
    component: () => import('@/components/siembras'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/addmantenimiento/:id', 
    name: 'add-mantenimiento', 
    component: () => import('@/components/siembras/addmantenimiento.vue')
  },
  {
    path: '/addsiembra/:id', 
    name: 'add-siembra', 
    component: () => import('@/components/siembras/addsiembra.vue')
  },
  {
    path: '/addproyecto/:id', 
    name: 'add-proyecto', 
    component: () => import('@/components/siembras/addproyecto.vue')
  },
  {
    path: '/editproyecto/:id', 
    name: 'edit-proyecto', 
    component: () => import('@/components/siembras/editproyecto.vue')
  },
  {
    path: '/editsiembra/:id', 
    name: 'edit-siembra', 
    component: () => import('@/components/siembras/editsiembra.vue')
  },
  {
    path: '/detailsiembra/:id', 
    name: 'detail-siembra', 
    component: () => import('@/components/siembras/detailsiembra.vue')
  },
  {
    path: '/siembras/clientlist/',
    name: 'client-list',
    component: () => import('@/components/siembras/clientsList')
  },
  {
    path: '/siembras/detailclient/:id',
    name: 'detail-cliente',
    component: () => import('@/components/siembras/detailClient')
  },
  {
    path: '/siembras/siembraclient/:id',
    name: 'siembra-cliente',
    component: () => import('@/components/siembras/siembraClient')
  },
  {
    path: '/siembras/addcliente', 
    name: 'add-cliente', 
    component: () => import('@/components/siembras/addcliente.vue')
  },
  {
    path: '/siembras/fotos/:id', 
    name: 'add-siembrafoto', 
    component: () => import('@/components/siembras/addfotoespeciesiembra.vue')
  },
  {
    path: '/siembras/participante/:id', 
    name: 'add-participante', 
    component: () => import('@/components/siembras/addparticipante.vue')
  },
  {
    path: '/siembras/arboles/:id', 
    name: 'arbol-detalle', 
    component: () => import('@/components/siembras/arboledetail.vue')
  },
  {
    path: '/siembras/siembraclient/:id2',
    name: 'siembra-cliente2',
    component: () => import('@/components/siembras/siembraClient')
  },
]
