import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

import Index from '../components/home/index.vue'
import detailEspecie from '../components/especies/detailespecie'
import addfotoEspecie from '../components/especies/addfotoespecie'

import restauracionRoutes from '@/components/restauracion/routes/restauracionRoute'
import siembrasRoutes from '@/components/siembras/routes/siembraRoute'
import empresasRoutes from '@/components/empresas/routes/empresasRoute'
import viveroRoutes from '@/components/vivero/routes/viveroRoute'
import bonosRoutes from '@/components/bonos/routes/bonosRoute'

Vue.use(VueRouter)

let router = new VueRouter({ 
  mode: 'history',
  routes: [
    ...bonosRoutes, // route module siembras
    ...siembrasRoutes, // route module siembras
    ...empresasRoutes, // route module empresas
    ...restauracionRoutes, // route module restauracion 
    ...viveroRoutes, // route module vivero 
  {
    path: '/',
    component: Index,
  },
  {
    path: '/admin',
    name: 'dashboard', 
    component: () => import('@/components/admin')
  },
  {
    path: '/register',
    name: 'register', 
    component: () => import('@/services/register.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/addespecie',
    name: 'addespecie', 
    component: () => import('@/components/especies/addespecie'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/especies',
    name: 'especies',
    component: () => import('@/components/especies'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/especies/addfoto/:id',
    name: 'addfotoespecies',
    component: addfotoEspecie,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/especies/:id',
    name: 'view-especie',
    component: detailEspecie
  },
  {
    path: '/siembrasApp', 
    name: 'siembrasApp', 
    component: () => import('@/components/amateapp/siembraslist'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/siembradetalle/:id', 
    name: 'view-siembraApp', 
    component: () => import('@/components/amateapp/siembradetalle.vue')
  },
  // restauracion
  {
    path: '/editespecie/:id', 
    name: 'edit-especie', 
    component: () => import('@/components/especies/editespecie.vue')
  },
  {
    path: '/especieSiembra/:id', 
    name: 'add-arbol', 
    component: () => import('@/components/amateapp/addArbol.vue')
  },
  {
    path: '/listeditespecieSiembra/:id', 
    name: 'list-edit-arbol', 
    component: () => import('@/components/amateapp/listeditArbol.vue')
  },
  {
    path: '/editespecieSiembra/:id', 
    name: 'edit-arbol', 
    component: () => import('@/components/amateapp/editArbol.vue')
  },
  // Proyectos
  {
    path: '/prueba2', 
    name: 'prueba2', 
    component: () => import('@/components/restauracion/prueba2.vue')
  }
]})

router.beforeEach((to, from, next) => {
  const user = store.state.auth.user 
  console.log(user)
    
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if(user.role === null)
      next('/')
    } else {
      next()
  }
  next()
})
// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   scrollBehavior: (to, from, savedPosition) => {
//     if (to.hash) return { selector:to.hash }
//     if (savedPosition) return savedPosition

//     return { x:0, y: 0 }
//   },
//   routes,
// })

export default router
