import axios from 'axios'
import API from '../services'

const API_URL = API.Appserver

class AuthService {
  login (user) {
    return axios
      .post(API_URL + 'authenticate', {
        email: user.email,
        password: user.password,
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data))
        }

        return response.data
      })
  }

  logout () {
    localStorage.removeItem('user')
  }

  register (user) {
    return axios.post(API_URL + 'register', {
      name: user.name,
      email: user.email,
      password: user.password,
      confirmPassword: user.confirmPassword,
      acceptTerms: user.acceptTerms,
    })
  }
}

export default new AuthService()
