<template lang='pug'>
v-container
  h1 agregar nueva foto
  v-col
    p Se escribe solo el nombre del archivo + extencion
    v-form
      v-text-field( v-model='fotourl', label='nombre.extrencion' )
      v-btn(@click="send()") Guardar
</template>

<script>
  import { HTTP } from '@/services'

export default {
  data () {
    return {
      fotourl: '',
      author: '',
      items: {},
    }
  },
  methods: {
    send () {
      const container = 'https://dendrospaces.blob.core.windows.net/bd-plantas/'
      this.isloading = true
      HTTP({
        method: 'post',
        url: '/dendros2/newarbolfoto',
        data: {
          fotoId: this.$route.params.id,
          info: ({
            especie: this.$route.params.id,
            fotourl: container + this.fotourl,
            author: this.author,
          }),
        },
      })
        .then(() => {
          this.$router.push({
            name: 'edit-siembra'
          })
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>
