export default [
  {
    path: '/rb', 
    name: 'rb', 
    component: () => import('@/components/empresas/rb/index.vue')
  },
  {
    path: '/coomeva', 
    name: 'coomeva', 
    component: () => import('@/components/empresas/coomeva/index.vue')
  },
  {
    path: '/recamier', 
    name: 'recamier', 
    component: () => import('@/components/empresas/recamier/index.vue')
  },
  {
    path: '/cotelco', 
    name: 'cotelco', 
    component: () => import('@/components/empresas/cotelco/index.vue')
  }
]
