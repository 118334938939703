export default [
  {
    path: '/restauracion', 
    name: 'restauracion', 
    component: () => import('@/components/restauracion')
  },
  {
    path: '/addsiembrapredio/:id', 
    name: 'add-siembra-predio', 
    component: () => import('@/components/restauracion/addsiembrapredio.vue')
  },
  {
    path: '/prediodetalle/:id', 
    name: 'view-predio', 
    component: () => import('@/components/restauracion/prediodetail.vue')
  },
  {
    path: '/addpredio', 
    name: 'add-predio', 
    component: () => import('@/components/restauracion/addpredio.vue')
  },
  {
    path: '/editpredio/:id', 
    name: 'edit-predio', 
    component: () => import('@/components/restauracion/editpredio.vue')
  },
]
