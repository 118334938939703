<template lang='pug'>
v-container
  app-bar
  v-row()
    v-col
      singin
</template>

<script>
import AppBar from '../empresas/layouts/appbar'
import singin from '../account/authentication'

  export default {
    name: 'Home',
    components: {
      AppBar,
      singin,
    }
  }
</script>
