export default [
  {
    path: '/vivero', 
    name: 'vivero', 
    component: () => import('@/components/vivero')
  },
  {
    path: '/addespeciesiembra', 
    name: 'addespeciesiembra', 
    component: () => import('@/components/vivero/addespesiembra')
  },
  {
    path: '/addespecieentrada', 
    name: 'addespecieentrada', 
    component: () => import('@/components/vivero/addentradaespecie')
  },
  {
    path: '/editsalidasiembra/:id', 
    name: 'editsalidasiembra', 
    component: () => import('@/components/vivero/editsalidasiembra')
  }
]
