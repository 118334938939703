<template lang='pug'>
v-container
  v-row
    v-col
      h1 detalle de la especie {{ items.nombre_comun }}
      v-card()
        v-col(v-for="(item, i) in items.fotos" :key='item.fotourl')
          v-img(:src='item.fotourl' width=600)

    v-col
      v-card
        v-card-title {{ items.nombre_comun }}
        v-card-subtitle(class="font-italic") Nombre cientifico: {{ items.nombre_cientifico }}
        v-card-text Descripcion: 
        v-card-text(v-html='items.descripcion')
        v-card-title Familia: {{ items.familia}}
</template>

<script>
  import { HTTP } from '@/services'

export default {
  data () {
    return {
      isloading: true,
      items: {},
    }
  },
  created () {
    HTTP.get('/dendros2/plantaspopulate/' + this.$route.params.id)
      .then(response => {
        this.isloading = false
        this.items = response.data
      })
      .catch(e => {
        this.erros.push(e)
      })
  },
}
</script>
