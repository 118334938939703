<template lang="pug">
v-container
  v-card(class="pa-5")
    ValidationObserver(ref="observer" v-slot="{ validate, reset }")
      form

        ValidationProvider(v-slot="{ errors }" name="email" rules="required|email")
          v-text-field(
            label="Email"
            v-model="user.email",
            :error-messages="errors"
            required
            outlined
            )

        ValidationProvider(v-slot="{ errors }" name="password" rules="required|min:6")
          v-text-field(
            label="contraseña"
            v-model="user.password"
            :error-messages="errors"
            type="password"
            required
            outlined
          )
        v-row.mb-7.mt-n4.justify-center
        v-row
          v-col
            v-btn(color="primary" :loading="isloading" @click="handleRegister") Ingresa
              
            p(class="mt-5" v-if="message == 'Error: Request failed with status code 500'")
              | Email o contraseña incorrecta

</template>

<script>
  import { required, email, min } from 'vee-validate/dist/rules'
  import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

  import User from '../../_models/user'

  setInteractionMode('eager')

  extend('required', {
    ...required,
    message: '{_field_} no puede estar vacio',
  })

  extend('email', {
    ...email,
    message: 'Email debe ser valido',
  })
  extend('min', {
    ...min,
    message: '{_field_} debe tener al menos {length} caracteres',
  })

  export default {
    name: 'Authentication',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        user: new User('', '', ''),
        submitted: false,
        message: '',
        isloading: false,
      }
    },
    computed: {
      loggedIn () {
        return this.$store.state.auth.status.loggedIn
      },
    },
    created () {
      if (this.loggedIn) {
        this.$router.push('/admin')
      }
    },
    methods: {
      handleRegister () {
        this.isloading = true
        this.$refs.observer.validate()
          .then(isValid => {
            if (!isValid) {
              this.isloading = false
              return
            }
            if (this.user.email && this.user.password) {
              this.$store.dispatch('auth/login', this.user)
                .then(
                  () => {
                    this.$router.push('/admin')
                  },
                  error => {
                    this.isloading = false
                    this.message = error
                    console.log(error)
                  },
                )
            }
          })
      },
    },
  }
</script>
