import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './auth.module'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState()], // tener cuidado al activar, modifica el bar de laa tab
  modules: { auth },

  state: {
    log2: true,
    yearTab: 0,
    predio: 'comfenalco'
  },
  mutations: {
    changePredio(state, predio) {
      state.predio = predio
    }
  }
})

export default store
