export default [
  {
    path: '/bonos', 
    name: 'bonos', 
    component: () => import('@/components/bonos'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/addbonos/:id', 
    name: 'add-bono', 
    component: () => import('@/components/bonos/addbono.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/editbono/:id', 
    name: 'edit-bono', 
    component: () => import('@/components/bonos/editbono.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/editbonoexcel/:id/:id2', 
    name: 'edit-bono-excel', 
    component: () => import('@/components/bonos/editbonoexcel.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/detailbono/:id', 
    name: 'detail-bono', 
    component: () => import('@/components/bonos/detailbono.vue'),
    
  },
  {
    path: '/qr/:id', 
    name: 'down-qr', 
    component: () => import('@/components/bonos/qr.vue'),
    meta: {
      requiresAuth: true
    }
  },
  // Treebuto
  {
    path: '/qrtreebuto/:id', 
    name: 'down-qr-treebuto', 
    component: () => import('@/components/bonos/bonosTreebuto/QrTreebuto.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pdftreebuto/:id', 
    name: 'pdf-treebuto', 
    component: () => import('@/components/bonos/bonosTreebuto/pdfTreebuto.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bonos-treebuto',
    name: 'bonos-treebuto',
    component: () => import('@/components/bonos/bonosTreebuto'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/addbonos', 
    name: 'add-bono-treebuto', 
    component: () => import('@/components/bonos/bonosTreebuto/addbonoTreebuto.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/editbonotreebuto/:id', 
    name: 'edit-bono-treebuto', 
    component: () => import('@/components/bonos/bonosTreebuto/editbonoTreebuto.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/detailbonotreebuto/:id', 
    name: 'detail-bono-treebuto', 
    component: () => import('@/components/bonos/bonosTreebuto/detailbonoTreebuto.vue'),
    
  },
  {
    path: '/senddetailbonotreebuto/:id', 
    name: 'send-detail-bono-treebuto', 
    component: () => import('@/components/bonos/bonosTreebuto/sendbonoTreebutodetail.vue'),
    
  },
 // alianza COOMEVA
  {
    path: '/alianzacoomeva',
    name: 'alianza-coomeva',
    component: () => import('@/components/bonos/alianzacoomeva'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/editlistlianzacoomeva/:id', 
    name: 'edit-list-alianza', 
    component: () => import('@/components/bonos/alianzacoomeva/editlistaalianzacoomeva.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/editbonoalianzacoomeva/:id', 
    name: 'edit-bono-alianza', 
    component: () => import('@/components/bonos/alianzacoomeva/editbonoalianzacoomeva.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sendmailcoomeva/:id', 
    name: 'send-mail-coomeva', 
    component: () => import('@/components/bonos/alianzacoomeva/sendmailcoomeva.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sendmailcoomevasembrados/:id', 
    name: 'send-mail-coomeva-sembrados', 
    component: () => import('@/components/bonos/alianzacoomeva/sendmailsembrados.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/siembradetallealianza/:id', 
    name: 'siembra-detalle-alianza', 
    component: () => import('@/components/bonos/alianzacoomeva/siembradetallealianza.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/detailbonoalianza/:id', 
    name: 'detail-bono-alianza', 
    component: () => import('@/components/bonos/alianzacoomeva/detailbonoAlianza.vue'),
  },
  {
    path: '/qralianza/:id', 
    name: 'down-qr-alianza', 
    component: () => import('@/components/bonos/alianzacoomeva/QrAlianza.vue')
  },
]
