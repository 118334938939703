import axios from 'axios'

// PRO
// on digitalocean
//const URL = 'https://goldfish-app-r2j9y.ondigitalocean.app/'

// on azure
const URL = 'https://kimu.azurewebsites.net/' // Azure
// const URL = 'localhost://8081'
// const URL = 'https://kimu.amatea.org/' 
const URLAPP = URL + 'amateaAuth/'

// DEV
// const URL = 'http://localhost:8081/'

export const HTTP = axios.create({
  baseURL: URL,
  headers: {
    'content-type': 'application/json',
    // eslint-disable-next-line
    'Authorization': 'Bearer' + window.localStorage.token,
  },
})

// conectar para authentication

const API = {
  Appserver: URLAPP
}
 export default API
